import Swiper from 'swiper';
import {Autoplay, Mousewheel, Navigation, Pagination, Scrollbar} from 'swiper/modules';

Swiper.use([Autoplay, Mousewheel, Navigation, Pagination, Scrollbar]);

document.querySelectorAll('.related-products-slider').forEach(el => {
  new Swiper(el, {
    createElements: true,
    wrapperClass: 'wp-block-post-template',
    slideClass: 'wp-block-post',
    slidesPerView: 'auto',
    navigation: {
      enabled: true,
    },
    mousewheel: {
      forceToAxis: true,
    },
  });
});
