const Money = Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'});

class Variation {
  constructor($form) {
    this.$form = $form;

    $form.on('found_variation', this.#found_variation.bind(this));

    const $wrap = $form.parents().eq(2);
    this.$price = $wrap.find('.wc-block-components-product-price, .price');
    this.$table = $('.product-table');

    this.price_html = this.$price.html();
  }

  static init(event) {
    let $form = $('.variations_form:last');
    if (event?.handleObj.type === 'woosq_loaded') {
      $form = $('.woosq-popup .variations_form');
    }

    if ($form.length !== 0) new Variation($form);
  }

  #found_variation(event, variation) {
    this.current_variation = variation;
    this.$price?.html(variation.price_html || this.price_html);
  }

}

Variation.init();
$(document.body).on('woosq_loaded', Variation.init);


let $tab = $('.product_tab');
let $content = $('.product_content');

$tab.click(function() {
  $tab.removeClass('active');
  showContent($(this).data('tab'));
});

showContent('tab-0')

function showContent(tabId) {
  $content.hide();
  $tab.removeClass('active');

  $(`#${tabId}`).show();
  $(`[data-tab="${tabId}"]`).addClass('active');
}

$(document).ready(function() {
  $('.is-style-condensed').each(function() {
    $(this).append('<button class="show-more-btn">Show More</button>');
  });

  $(document).on('click', '.show-more-btn', function() {
    var $this = $(this);
    var $parentDiv = $this.closest('.is-style-condensed');
    var $condenseDiv = $parentDiv.find('.condense, .show');

    if ($condenseDiv.hasClass('show')) {
      $condenseDiv.removeClass('show').addClass('condense');
      $this.text('Show More');
    } else {
      $condenseDiv.removeClass('condense').addClass('show');
      $this.text('Show Less');
    }
  });
});
